var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"结算订单","width":"800px"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[(_vm.stop === false)?_c('span',{staticStyle:{"margin-left":"30px","color":"red"}},[_vm._v("请确认此订单款项是否结清，确认客户补足剩余支付款项，或退回多余款项，请慎重操作。")]):_vm._e(),(_vm.stop === true)?_c('span',{staticStyle:{"margin-left":"30px","color":"red"}},[_vm._v("确定结算吗？结算后，订单状态变更为已终止，订单下未完成的任务将自动取消，并且无法进行恢复；请确认此订单款项是否结清，确认客户补足剩余支付款项，或退回多余款项，请慎重操作。")]):_vm._e(),_c('a-form',{staticClass:"custom-compact-form",staticStyle:{"margin-top":"15px"},attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 8 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"结算金额"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['settle_fee', {
            normalize: this.$lodash.trim
          }]),expression:"['settle_fee', {\n            normalize: this.$lodash.trim\n          }]"}],attrs:{"disabled":true,"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"已实际支付金额"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['paid_fee', {
            normalize: this.$lodash.trim
          }]),expression:"['paid_fee', {\n            normalize: this.$lodash.trim\n          }]"}],attrs:{"disabled":true,"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"剩余应付金额"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['unpaid_fee', {
            normalize: this.$lodash.trim
          }]),expression:"['unpaid_fee', {\n            normalize: this.$lodash.trim\n          }]"}],attrs:{"disabled":true,"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":" ","colon":false}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:(['is_unpaid_fee_zero', { valuePropName: 'checked' }]),expression:"['is_unpaid_fee_zero', { valuePropName: 'checked' }]"}],style:({ display: 'inline-block', width: '250%' }),on:{"change":_vm.changeUnpaidFee}},[_vm._v("客户已支付，勾选后剩余应付金额为0，已实际支付金额=结算金额-优惠金额")])],1),_c('a-form-item',{attrs:{"label":"应退金额"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['withdrawn_fee', {
            normalize: this.$lodash.trim
          }]),expression:"['withdrawn_fee', {\n            normalize: this.$lodash.trim\n          }]"}],attrs:{"disabled":true,"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":" ","colon":false}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:(['is_withdrawn_fee_zero', { valuePropName: 'checked' }]),expression:"['is_withdrawn_fee_zero', { valuePropName: 'checked' }]"}],style:({ display: 'inline-block', width: '120%' }),on:{"change":_vm.changeWithdrawnFee}},[_vm._v("已退款给客户，勾选后应退金额为0")])],1),_c('a-form-item',{attrs:{"label":"备注"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['remark', {
            normalize: this.$lodash.trim,
            rules: [
              { max: 50, message: '最多50个字符' } ]
          }]),expression:"['remark', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { max: 50, message: '最多50个字符' },\n            ]\n          }]"}]})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }