<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="结算订单"
      width="800px"
      @ok="handleSubmit"
    >
      <span v-if="stop === false" style="margin-left:30px;color: red;">请确认此订单款项是否结清，确认客户补足剩余支付款项，或退回多余款项，请慎重操作。</span>
      <span v-if="stop === true" style="margin-left:30px;color: red;">确定结算吗？结算后，订单状态变更为已终止，订单下未完成的任务将自动取消，并且无法进行恢复；请确认此订单款项是否结清，确认客户补足剩余支付款项，或退回多余款项，请慎重操作。</span>
      <a-form
        class="custom-compact-form"
        style="margin-top:15px;"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 8 }"
        @submit="handleSubmit"
      >
        <a-form-item label="结算金额">
          <a-input
            :disabled="true"
            v-decorator="['settle_fee', {
              normalize: this.$lodash.trim
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="已实际支付金额">
          <a-input
            :disabled="true"
            v-decorator="['paid_fee', {
              normalize: this.$lodash.trim
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="剩余应付金额">
          <a-input
            :disabled="true"
            v-decorator="['unpaid_fee', {
              normalize: this.$lodash.trim
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label=" " :colon="false">
          <a-checkbox @change="changeUnpaidFee" v-decorator="['is_unpaid_fee_zero', { valuePropName: 'checked' }]" :style="{ display: 'inline-block', width: '250%' }">客户已支付，勾选后剩余应付金额为0，已实际支付金额=结算金额-优惠金额</a-checkbox>
        </a-form-item>
        <a-form-item label="应退金额">
          <a-input
            :disabled="true"
            v-decorator="['withdrawn_fee', {
              normalize: this.$lodash.trim
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label=" " :colon="false">
          <a-checkbox @change="changeWithdrawnFee" v-decorator="['is_withdrawn_fee_zero', { valuePropName: 'checked' }]" :style="{ display: 'inline-block', width: '120%' }">已退款给客户，勾选后应退金额为0</a-checkbox>
        </a-form-item>
        <a-form-item label="备注">
          <a-textarea
            v-decorator="['remark', {
              normalize: this.$lodash.trim,
              rules: [
                { max: 50, message: '最多50个字符' },
              ]
            }]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>

import { billingOrder } from '@/api/order'
import { findTenantUserOptions } from '@/api/user'
import { formatDateTimeMin } from '@/utils/time'

export default {
  name: 'BillingOrder',
  components: {
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    stop: {
      type: Boolean,
      required: true
    },
    record: {
      type: Object,
      default: function() {
        return {}
      }
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'billing_order' }),
      submitting: false,
      data: {},
      userOptions: [],
      loadingUserOptions: false
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchUserOptions()
    this.fetchFormData()
  },
  methods: {
    changeUnpaidFee(e) {
      if (e.target.checked) {
        this.$nextTick(() => {
          this.form.setFieldsValue({ unpaid_fee: 0 })
        })
      } else {
        this.$nextTick(() => {
          this.form.setFieldsValue({ unpaid_fee: this.record.unpaid_fee })
        })
      }
    },
    changeWithdrawnFee(e) {
      if (e.target.checked) {
        this.$nextTick(() => {
          this.form.setFieldsValue({ withdrawn_fee: 0 })
        })
      } else {
        this.$nextTick(() => {
          this.form.setFieldsValue({ withdrawn_fee: this.record.withdrawn_fee })
        })
      }
    },
    handleStartOpenChange() {
      const fieldsValue = this.form.getFieldsValue()
      const startValue = fieldsValue.start_at
      const endValue = fieldsValue.end_at
      if (endValue && startValue) {
        if (endValue.valueOf() < startValue.valueOf()) {
          this.endOpen = false
          this.$warning({
            title: '结束时间不能小于开始时间',
            content: ''
          })
          this.form.setFieldsValue({ start_at: null })
        }
      }
    },
    handleEndOpenChange() {
      const fieldsValue = this.form.getFieldsValue()
      const startValue = fieldsValue.start_at
      const endValue = fieldsValue.end_at
      if (endValue && startValue) {
        if (endValue.valueOf() < startValue.valueOf()) {
          this.endOpen = false
          this.$warning({
            title: '结束时间不能小于开始时间',
            content: ''
          })
          this.form.setFieldsValue({ end_at: null })
        }
      }
    },
    fetchFormData() {
      this.$nextTick(() => {
        var startDate = this.$moment(this.$moment().format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm')
        if (this.stop === false) {
          this.form.setFieldsValue({ start_at: startDate, end_at: startDate, settle_fee: this.record.settle_fee, paid_fee: this.record.paid_fee, unpaid_fee: this.record.unpaid_fee, withdrawn_fee: this.record.withdrawn_fee, content: this.record.hall_name + '(逝者姓名：' + this.record.death_name + ')，请家属与工作人员准备参加告别出殡仪式。' })
        } else {
          this.form.setFieldsValue({ settle_fee: this.record.settle_fee, paid_fee: this.record.paid_fee, unpaid_fee: this.record.unpaid_fee, withdrawn_fee: this.record.withdrawn_fee })
        }
      })
    },
    fetchUserOptions() {
      this.loadingUserOptions = true
      findTenantUserOptions().then((res) => {
        if (res.code === 0) {
          this.userOptions = res.data
        }
        this.loadingUserOptions = false
      })
    },
    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      const fieldsValue = this.form.getFieldsValue()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const values = {
            ...fieldsValue,
            begin_time: formatDateTimeMin(fieldsValue.start_at),
            end_time: formatDateTimeMin(fieldsValue.end_at)
          }
          if (parseFloat(fieldsValue.paid_fee) < parseFloat(fieldsValue.settle_fee)) {
            const vm = this
            vm.$confirm({
              title: '提示',
              content: (
                <div>
                  <p>实付金额低于应付金额，确定结算吗？</p>
                </div>
              ),
              okText: '确定',
              okType: 'danger',
              cancelText: '取消',
              onOk() {
                billingOrder(Object.assign({ order_id: vm.id }, values)).then((res) => {
                  if (res.code === 0) {
                    // 关闭模态框
                    vm.isShow = false
                    vm.$router.push({ name: 'tenant_orders' })
                  }
                  vm.submitting = false
                })
              },
              onCancel() {
                vm.submitting = false
              }
            })
          } else {
            billingOrder(Object.assign({ order_id: this.id }, values)).then((res) => {
              if (res.code === 0) {
                // 关闭模态框
                this.isShow = false
                this.$router.push({ name: 'tenant_orders' })
              }
              this.submitting = false
            })
          }
        }
      })
    }
  }
}
</script>
